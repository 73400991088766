import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'

import { AddGuestParams, AddMandateParams, AddMandateProofParams, Client, CreateClientParams, UpdateClientParams, UpdateGuestParams } from './types'
import { BaseError, objectify } from '@courseur/utils'
import { useEzioAPI } from '@courseur/services'

export const useClientsStore = defineStore('clients', () => {
    const { api } = useEzioAPI()

    const all: Ref<{ [id: string]: Client }> = ref({})
    const active: Ref<Client[]> = ref([])
    const inactive: Ref<Client[]> = ref([])

    const fetchCollection = async (params = { status: 0, agency_id: null }) => {
        try {
            const payload = Object.assign({}, params, {
                pagination: false
            })
            const response = await api.get('clients', payload)
            storeIndex(response.data, params)
            return response.data
        } catch (error) {
            //
        }
    }

    const fetch = async (clientId: string) => {
        try {
            if (!clientId) return
            const response = await api.get('clients/' + clientId)
            store([response.data])
            return response.data
        } catch (error) {
            //
        }
    }

    const create = async (data: CreateClientParams) => {
        try {
            const response = await api.post('clients', data)
            store([response.data])

            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const update = async (data: UpdateClientParams) => {
        try {
            const params = Object.assign({}, data)
            const response = await api.put('clients/' + data.id, params)
            store([response.data])

            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const createOrUpdate = async (data: UpdateClientParams | CreateClientParams) => {
        if ('id' in data) {
            return await update(data)
        } else {
            return await create(data)
        }
    }

    const addMandate = async (data: AddMandateParams) => {
        try {
            data.mandate.iban = data.mandate.iban.split(' ').join('')
            data.mandate.bic = data.mandate.bic.split(' ').join('')
            const response = await api.post('clients/' + data.client.id + '/mandates', data.mandate)
            if (response.data.mandates) {
                const response2 = await update({
                    id: response.data.id,
                    selected_mandate: response.data.mandates[response.data.mandates.length - 1].id
                })
                store([response2])
            } else {
                console.log('no mandates returned')
            }
        } catch (error: any) {
            //
        }
    }
    const deleteMandate = async ({ client_id, mandate_id }) => {
        try {
            const response = await api.delete('clients/' + client_id + '/mandates/' + mandate_id)
            store([response.data])
        } catch (error: any) {
            console.log(error)
        }
    }
    const addMandateProof = async (data: AddMandateProofParams) => {
        try {
            const response = await api.put('clients/' + data.id + '/mandates/' + data.mandateId, {
                mandate_proof: data.mandateProof
            })
            store([response.data])
        } catch (error: any) {
            console.log(error)
        }
    }

    const archive = async (data: { client: Client }) => {
        try {
            await update({
                id: data.client.id,
                // refund_amount: data.refundAmount,
                status: 1
            })
            const index = active.value.findIndex((c) => data.client.id === c.id)

            active.value.splice(index, 1)
        } catch (error: any) {
            //
        }
    }
    const activate = async (data: { client: Client }) => {
        try {
            await update({
                id: data.client.id,
                // refund_amount: data.refundAmount,
                status: 0
            })
            const index = inactive.value.findIndex((c) => data.client.id === c.id)
            inactive.value.splice(index, 1)
        } catch (error: any) {
            //
        }
    }

    const addGuest = async (data: AddGuestParams) => {
        const params = Object.assign({}, data.guest)
        const response = await api.post('clients/' + data.id + '/guests', params)
        store([response.data])
    }
    const updateGuest = async (data: UpdateGuestParams) => {
        const params = Object.assign({}, data.guest)
        const response = await api.put('clients/' + data.id + '/guests/' + data.guest.id, params)
        store([response.data])
    }

    const deleteGuest = async (data: UpdateGuestParams) => {
        const params = Object.assign({}, data.guest)
        const response = await api.delete('clients/' + data.id + '/guests/' + data.guest.id, params)
        store([response.data])
    }

    const storeIndex = (clients: Client[], params: any) => {
        if (params.status === 0) {
            active.value = clients
        }
        if (params.status === 1) {
            inactive.value = clients
        }
    }

    const store = (clients: Client[]) => {
        all.value = Object.assign({}, all.value, objectify(clients))
    }

    const reset = () => {
        all.value = {}
        active.value = []
        inactive.value = []
    }

    return {
        all,
        active,
        inactive,
        fetchCollection,
        fetch,
        create,
        update,
        createOrUpdate,
        addMandate,
        deleteMandate,
        addMandateProof,
        archive,
        activate,
        addGuest,
        updateGuest,
        deleteGuest,

        reset
    }
})
